import React from 'react'
import Link from '@material-ui/core/Link'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'


const styles = theme => ({
  bar: {
    background: 'none',
    boxShadow: 'none',
    border: 0,
  },
  menu: {
    justifyContent: 'flex-end',
    '& a': {
      color: 'white',
      fontSize: theme.spacing(1.5),
      padding: theme.spacing(0, 1),
      '@media (min-width:400px)': {
        fontSize: theme.spacing(2),
        padding: theme.spacing(0, 2),
      },
      '@media (min-width:600px)': {
        fontSize: theme.spacing(2.5),
        padding: theme.spacing(0, 3),
      },
    },
  },
})


const HideMenu = props => {
  const { children } = props
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger} timeout={250}>
      {children}
    </Slide>
  )
}


const Menu = props => {
  const {classes} = props
  return (
    <HideMenu {...props}>
      <AppBar id="menu" className={classes.bar}>
        <Toolbar className={classes.menu}>
          <Link href="/#news">News</Link>
          <Link href="/#projects">Projects</Link>
          <Link href="/#publications">Publications</Link>
          <Link href="/#people">People</Link>
          <Link href="/#contact">Contact</Link>
        </Toolbar>
      </AppBar>
    </HideMenu>
  )
}


export default withStyles(styles)(Menu)
