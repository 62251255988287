import React from 'react'
import { Card, CardMedia, CardContent, Hidden, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


import { projects } from '../content/projects'


const styles = theme => ({
  projects: {
    margin: '5vw',
    width: '90vw',
    padding: theme.spacing(3, 0),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4, 0),
      width:'90vw',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5, 0),
      width: '80vw',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5, 0),
      width: '70vw',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5, 0),
      width: '60vw',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5, 0),
      width: '50vw',
    },
  },
  title: {
    color: 'white',
    margin: theme.spacing(3, 0, 10, 0),
  },
  project: {
    margin: theme.spacing(10, 0),
  },
  cover: {
    position: 'relative',
    width: '100%',
    height: '15vw',
    marginRight: theme.spacing(3),
    '@media (min-width:1200px)': {
      float: 'left',
      width: '15vw',
      height: '25vh',
    },
    '& h4': {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: 0,
      color: 'whitesmoke',
      fontWeight: '900',
      background: '#111',
      padding: theme.spacing(2),
    },
  },
  content: {
    '& h6': {
      color: '#111',
      marginBottom: theme.spacing(1),
    },
    '& p': {
      color: '#111',
    },
  },
})


class Projects extends React.Component {

  renderProjects() {
    const {classes} = this.props
    return projects.filter(project => project.active).map(project => (
      <Card className={classes.project} key={project.name}>
        <CardMedia className={classes.cover}
          image={project.image}
          title={project.name}>
          <Hidden mdDown>
            <Typography component="h4" variant="h4">
              {project.name}
            </Typography>
          </Hidden>
        </CardMedia>
        <CardContent className={classes.content}>
          <Hidden lgUp>
            <Typography component="h5" variant="h5">
              <b>{project.name}</b>
            </Typography>
          </Hidden>
          <Typography component="h6" variant="h6">
            <b>{project.subtitle}</b>
          </Typography>
          <Typography component="p" variant="body1">
            {project.description}
          </Typography>
        </CardContent>
      </Card>
    ))
  }

  renderPastProjects() {
    const {classes} = this.props
    return projects.filter(project => !project.active).map(project => (
      <Card className={classes.project} key={project.name}>
        <CardMedia className={classes.cover}
          image={project.image}
          title={project.name}>
          <Hidden mdDown>
            <Typography component="h4" variant="h4">
              {project.name}
            </Typography>
          </Hidden>
        </CardMedia>
        <CardContent className={classes.content}>
          <Hidden lgUp>
            <Typography component="h5" variant="h5">
              <b>{project.name}</b>
            </Typography>
          </Hidden>
          <Typography component="h6" variant="h6">
            <b>{project.subtitle}</b>
          </Typography>
          <Typography component="p" variant="body1">
            {project.description}
          </Typography>
        </CardContent>
      </Card>
    ))
  }

  render() {
    const {classes} = this.props
    return (
      <div id="projects" className={classes.projects}>
        <Typography
          component="h4"
          variant="h4"
          className={classes.title}>
          Research Projects
        </Typography>
        {this.renderProjects()}
        <Typography
          component="h4"
          variant="h4"
          className={classes.title}>
          Past Projects
        </Typography>
        {this.renderPastProjects()}
      </div>
    )
  }
}


export default withStyles(styles)(Projects)
