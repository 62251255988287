import React from 'react'
import {Power0} from 'gsap'
import Vehicle from './Vehicle'


class Cyber extends React.Component {

  componentDidMount() {
    this.animate()
  }

  componentDidUpdate() {
    this.animate()
  }

  animate() {
    const delay = Math.round(Math.random() * 30) + 60
    const speed = Math.round(Math.random() * 10) + 5
    this.props.timeline
      .to('.cyber', speed, {
        x: 1600,
        delay: delay,
        ease: Power0.easeNone,
      })
  }

  render() {
    return (
      <Vehicle>
        <g className="cyber" transform={'translate(-100 0)'}>
          <path fill="#c0c0c0" d="M26.095 586L39 590.09V595H8v-6z"/>
          <circle fill="#000" cx="35.5" cy="594.5" r="2.5"/>
          <circle fill="#000" cx="13.5" cy="594.5" r="2.5"/>
        </g>
      </Vehicle>
    )
  }
}

export default Cyber
