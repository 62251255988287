import React from 'react'
import {TimelineMax} from 'gsap'

import Cyber from './Cyber'


class Traffic extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      timeline: new TimelineMax()
    }
  }

  componentDidMount() {
    const {timeline} = this.state
    this.props.timeline.add(timeline)
  }

  componentDidUpdate() {
    const {timeline} = this.state
    this.props.timeline.add(timeline)
  }

  render() {
    const {timeline} = this.state
    return (
      <React.Fragment>
        <Cyber timeline={timeline} />
      </React.Fragment>
    )
  }
}


export default Traffic
