import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box, IconButton, Link, Paper, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'


const styles = theme => ({
  publication: {
    margin: theme.spacing(5, 0),
    '& h5': {
      color: '#111',
    },
    '& p': {
      color: '#111',
    },
  },
  bibtexButton: {
    display: 'inline',
    cursor: 'pointer',
    userSelect: 'none',
    '& .MuiSvgIcon-root': {
      verticalAlign: 'middle',
    },
  },
  bibtex: {
    padding: theme.spacing(2),
    fontSize: theme.spacing(2),
    whiteSpace: 'pre',
    overflow: 'scroll',
  },
})


class Publication extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showBibtex: false,
    }
  }

  showBibtex() {
    const { showBibtex } = this.state
    this.setState({
      showBibtex: !showBibtex,
    })
  }

  renderPublication() {
    const { classes, data } = this.props
    const { showBibtex } = this.state
    return (
      <div className={classes.publication}>
        <Typography component="h5" variant="h5">{data.title}</Typography>
        <Typography component="p" variant="body1">{data.authors}</Typography>
        {!!data.booktitle ? (
          <Typography component="p" variant="body1">
            {`${data.booktitle} ${!!data.year ? data.year : ''}`}
          </Typography>
        ) : (
          <Typography component="p" variant="body1">
            {`${data.type} published in ${!!data.journal ? `${data.journal}; ` : ''} ${!!data.year ? data.year : ''}`}
          </Typography>
        )}
        <Typography
          component="p"
          variant="body1"
          onClick={() => this.showBibtex()}
          className={classes.bibtexButton}>
          BibTeX
          {!!showBibtex ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </Typography>
        {!!data.url ? (
          <IconButton component={Link} href={data.url} target="_blank" rel="noopener noreferrer">
            <PictureAsPdfIcon />
          </IconButton>
        ) : null}
        {!!showBibtex ? (
          <Paper variant="outlined" className={classes.bibtex}>
            <Box fontFamily="Monospace">
              {data.bibtex}
            </Box>
          </Paper>
        ) : null}
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPublication()}
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Publication)
