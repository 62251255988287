import React from 'react'
import {TimelineMax, Power2} from 'gsap'


class Mountains extends React.Component {

  componentDidMount() {
    this.timeline = new TimelineMax({paused:true})
    this.timeline
      .fromTo('.mountains-3', 3, {
        fill: '#fefefe',
        ease: Power2.easeInOut,
      }, {
        fill: '#e5e5e5',
        ease: Power2.easeInOut,
      }, 'start')
      .fromTo('.mountains-2', 3, {
        fill: '#152946',
        ease: Power2.easeInOut,
      }, {
        yoyo: true,
        fill: '#03070b',
        ease: Power2.easeInOut,
      }, 'start')
      .fromTo('.mountains-1', 3, {
        fill: '#2359A8',
        ease: Power2.easeInOut,
      }, {
        fill: '#0d213e',
        ease: Power2.easeInOut,
      }, 'start')
  }

  componentDidUpdate(props) {
    const {animate} = this.props
    if ( animate ) {
      this.timeline.play()
    } else {
      this.timeline.reverse()
    }
  }

  render() {
    return (
      <React.Fragment>
        <path className="mountains-3" data-depth="0.05" fill="#FEFEFE" fillRule="nonzero" d="M1250.091 221.7l87.166-25.686.118.022 54.365 10.167 34.602 13.37 74.158 21.837 99.5 27.112V400H-100V216.35l99.5-24.255 40.059-6.88L61.5 173.058l39.842 18.63H146.5l26.5 19.596 55.004 5.067 68.964-12.202L396 92.95l16.44 16.52 15.56 3.597 10-12.206 17.996 30.441 25.241 19.644 14.634-8.205 13.24-22.68 8.19 8.369 13.154 2.936 25.14-5.122 13.727-13.177 12.097-12.206 12.937 19.2 13.285 11.305 11.478 5.94 10.777 5.753 10.043-5.94.178-.063 22.894 5.685 8.168-10.548 22.465 10.548 48.989 8.205 53.628-7.464.137.02 26.116 7.444 16.356-2.058 53.547 24.17h44.606l24.995 22.99 30.819 3.168 6.483 14.103 21.222 6.213 12.533-4.009 10.617 9.702 61.645-13.94 94.182 30z"/>
        <path className="mountains-2" data-depth="0.075"fill="#152946" fillRule="nonzero" d="M1250.091 231.7l87.166-25.686.118.022 54.365 10.167 34.602 13.37 74.158 21.837 99.5 27.112V530H-100V256.35l99.5-34.255 43.026-9.112.126.04 58.69 18.666 126.662 24.662 68.964-12.202 91.847-36.614 48.439-19.258.144-.034 39.325-3.23L492.548 173l17.99 5.492.121.056 10.174 6.405 20.19-6.47 23.414-5.455 10.514-6.785 9.52 6.81h25.08l.205.044 9.363 4.207h55.113l10.044-5.94.178-.063 32.615-5.058 8.168-10.548L759.131 173h23.212l37.288-17.305.137.02 26.116 7.444 16.356-2.058 30.177 21.957h44.606l24.995 22.989 30.819 3.168 6.483 14.103 21.222 6.213 12.533-4.009 10.617 9.702 61.645 16.06h94.182z"/>
        <path className="mountains-1" data-depth="0.1" fill="#2359A8" fillRule="nonzero" d="M1250.591 425.853l87.166-20.538.118.017 54.365 8.13 34.602 10.691 74.158 17.46 99 73.428V700H-100V466.204L0 418.173l43.026-7.285.126.032 58.69 14.924 126.662 19.72 68.964-9.756 91.847-29.277 48.439-33.8 50.144-55.231 19.325 5.698 15.825.515 17.99-4.809 10.121 8.325 10.174 6.962 10.19 3.108 23.414-4.363 22.06-14.104 37.973 14.124h25.082l10.204 9.236 9.363 3.364 15.113 9.2 5.044 2.612 5.178 1.79 22.615-4.045 28.168.767 44.394 9.2.137.016 26.116 5.953 16.356-1.646 30.177 17.556h44.606l24.995 18.382 30.819 2.534 6.483 11.276 21.222 4.968 12.533-3.206 10.617 7.758 61.645 12.841h94.182z"/>
      </React.Fragment>
    )
  }
}


export default Mountains
