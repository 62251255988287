import React from 'react'
import { TextField } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'


const CustomTextField = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
        opacity: 0.85,
      },
      color: '#111',
    },
    '& .MuiInput-input': {
      paddingLeft: '5px',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      color: '#111',
      transition: 'background 0.3s ease',
    },
    '& label': {
      paddingLeft: '5px',
    },
    '& label.Mui-focused': {
      color: '#111',
    },
    '&:hover .MuiInput-input': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
    '&:hover .MuiInput-underline:before': {
      borderBottomColor: '#111',
      borderBottom: '3px solid',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#111',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#111',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0px, -10px)',
    },
  },
})(TextField)


class Input extends React.Component {

  handleOnChange(e) {
    const value = e.target.value
    this.props.updateText(value)
  }

  render() {
    const {text, label} = this.props
    return (
      <CustomTextField
        ref="input"
        name={text}
        label={label}
        value={text}
        disabled={false}
        autoFocus={false}
        autoComplete="off"
        onChange={this.handleOnChange.bind(this)}
        fullWidth
      />
    )
  }
}


export default Input
