import image1 from '../images/covid/image1.png'
import image2 from '../images/covid/image2.png'
import image3 from '../images/covid/image3.png'
import image4 from '../images/covid/image4.png'
import image5 from '../images/covid/image5.png'
import image6 from '../images/covid/image6.png'
import image7 from '../images/covid/image7.png'
import image8 from '../images/covid/image8.png'


export const articles = [{
  slug: 'la-county-covid-incidence',
  title: 'LA County COVID-19 Incidence',
  subtitle: '',
  date: 'March 29, 2020',
  authors: 'Ashwin Shreyas Rao, Gleb Satyukov and Kristina Lerman',
  body: [{
    type: 'text',
    content: 'Los Angeles County Health Department is tracking novel coronavirus cases within the region. Given that testing has severely lagged, the data does not reflect the true magnitude of the outbreak. As testing is ramping up, some trends are beginning to emerge. When we first plotted the number of cases in different parts of LA Country (in March 25, 2020), Brentwood and West Hollywood were places with the highest number of confirmed Covid-19 cases. However, it does not look like Brentwood has seen any new cases in the past week, while West Hollywood is continuing to grow. This seems to suggest that early access to testing, rather than susceptibility drove early numbers in Brentwood. ',
  }, {
    type: 'text',
    content: 'To get a better sense of the incidence of Covid-19 cases, we normalize LA County numbers by the population of each neighborhood. West Hollywood pops out of the map, along with neighboring areas, such as Beverly Hills to the west and Hollywood/Hollywood Hills to the east. In San Fernando Valley, Encino and Tarzana are the most affected areas. Overall, Covid-19 seems to be the problem of affluent coastal and mountain neighborhoods, which again must be due to testing. How long before we see the cases heat up in the inland neighborhoods?',
  }, {
    type: 'iframe',
    content: 'https://minds.isi.edu/covid-la',
  }],
}, {
  slug: 'social-distancing-analysis',
  title: 'There may be no safe crowd size in social distancing',
  subtitle: '',
  date: 'March 29, 2020',
  authors: 'Keith Burghardt and Kristina Lerman',
  body: [{
    type: 'text',
    content: 'Social distancing has become a near-universal intervention to mitigate the spread of COVID-19. Social distancing measures implemented by various states and municipalities include school and business closures, and prohibiting crowds from gathering. However, the limit on crowds size has varied, ranging from 250 to 2. Does a safe crowd size exist for limiting the spread of the disease?',
  }, {
    type: 'text',
    content: 'We begin to answer this question using two real-world social networks, Brightkite and Gowalla. These dataset report locations of about 150K people with a high degree of time and location granularity. If two people report they are in the same place within a few minutes of each other1, we can reasonably assume they have been in close proximity. If either is infected, then the other would be exposed and potentially infected.',
  }, {
    type: 'text',
    content: 'We created a contact network linking people who were in the same location within a short time interval. To approximate social distancing intervention, we remove any location that had a crowd larger than size S, which we vary from 2 to 200. To gauge the impact of social distancing, we measured the size of the largest connected component, a jargon network scientists use to refer to how many people are connected through social links and could become infected in an outbreak. This is a worst-case scenario that is useful in telling us potential spread. Figure 1 shows our initial results. We find that stopping people from attending popular locations does not have an appreciable effect on the contact network. Only when we limit the crowds to 5 people or less do we see the contact network begin to substantially fragment, reducing the size of the largest connected component.2 The many personal interactions maintain the connectivity of the contact network, allowing infections to spread widely.',
  }, {
    type: 'image',
    content: image8,
  }, {
    type: 'text',
    content: 'Figure 1: Largest connected component of contact networks versus intervention strategy. Removing popular locations (x-axis) in 2 spatio-social networks, Brightkite and Gowalla, does not substantially reduce the contact network’s largest connected component unless we remove almost all interactions.',
  }, {
    type: 'text',
    content: 'Postscript: While these results present the worst-case scenario of who might be infected, they offer some initial light onto the complex strategies needed to arrest pandemics. Moreover, there are many limitations of the contact network we use. The locations are self-reported, therefore people may not report when they are, we do not know when they interact outside of the locations. Therefore the true contact network is far larger and potentially much more dense. While our method does not capture actual disease dynamics, we are currently testing how different intervention strategies may impact the spread of COVID-19-like diseases using stochastic disease models. The results of these approaches will be highlighted in an upcoming post.',
  }, {
    type: 'text',
    content: 'Notes:',
  }, {
    type: 'text',
    content: '1. We varied this time between 20 minutes and an hour and find qualitatively similar results.',
  }, {
    type: 'text',
    content: '2. We also explored the distribution of all component sizes as a function of the locations removed and only see minor change in this distribution, thus the small effect on the largest connected component is representative of most components.',
  }],
},{
  slug: 'ls-heatmap',
  title: 'Los Angeles County COVID-19 Cases',
  subtitle: '',
  date: 'March 24, 2020',
  authors: 'Kristina Lerman and Ashwin Rao',
  body: [{
    type: 'text',
    content: 'Los Angeles County Health Department is tracking novel coronavirus cases within the region. The department provides detailed statistics about hospitalizations, agents, etc., but we thought it would be helpful to map the cases. As of noon of March 23, 2020, there were 546 cases. Here is what it looks like on the map (we still have to add Long Beach and Pasadena with 20 total cases).',
  }, {
    type: 'image',
    content: image7,
  }, {
    type: 'text',
    content: 'The hotspots of cases appear to be concentrated on the Westside, the affluent neighborhoods of Brentwood, West Hollywood, Santa Monica and Beverly Hills. Could this be because the residents there travel more, or are tested more?',
  }],
}, {
  slug: 'opentable',
  title: 'Do Local Conditions Affect how People React to the Pandemic? - OpenTable',
  subtitle: '',
  date: 'March 20, 2020',
  authors: 'Yuzi He, Kristina Lerman',
  body: [{
    type: 'text',
    content: 'The Coronavirus (Covid-19) pandemic hit the states at different times. Washington state announced its first coronavirus case on January 21, 2020, with first death on February 29, while Texas did not encounter its first case of community spread of the virus until March 11. The reactions of different states to the pandemic also varied. Washington was the first state to declare a public health emergency (February 29, 2020), with California quickly following (March 4, 2020), but Texas did not declare an emergency until March 13, 2020, when President Trump declared a national emergency.',
  },{
    type: 'text',
    content: 'Do reactions of the population to the pandemic depend on the severity of local conditions? To gauge public reaction to the pandemic, we used restaurant booking data provided by OpenTable. When times are good, people reserve tables in restaurants. In fact, Valentine’s day (2020) saw many more restaurant reservations compared to Valentine’s Day of 2019 [possible source? https://blog.opentable.com/2020/top-dining-trends-data-valentines-day-opentable/ ref 2]. However, things quickly went downhill. The plot below shows the change in the number of bookings on that day compared to a year before, since February 18. Bookings are steady (with some weekly variation) until day 20 (March 9).',
  }, {
    type: 'image',
    content: image6,
  }, {
    type: 'text',
    content: 'Surprisingly, the decline follows the same pattern across all states. It looks like people are reacting to global conditions (stock market? news?) rather than the level of the pandemic or disaster declarations in their state. The decline mirrors the global decline in restaurant reservations starting on Monday March 9. What does this mean? A policy, carried out at a national level, may work as well changing behavior as more local policies enacted by states.',
  }],
}, {
  slug: 'covid-19-geo',
  title: 'Twitter Covid-19 Preliminary Geo-analysis',
  subtitle: '',
  date: 'March 19, 2020',
  authors: 'Ashwin Rao, Emily Chen, Emilio Ferrara, Kristina Lerman',
  body: [{
    type: 'text',
    content: 'Please first read the following paper on the Covid-19 Twitter data. We plotted the spatial distribution of tweets mentioning Covid-19-related terms posted during February 19-22, 2020. Tweets with spatial coordinates represent a tiny fraction of all tweets (~0.5%). Still, there are enough of them to see national - and worldwide - trends.',
  }, {
    type: 'text',
    content: 'First, at the national level, it is striking how much more early discussions there were in California about the Coronavirus than in the rest of the country. Interestingly enough, people in Washington state did not talk quite as much about it. Granted, its population is 5-6 times smaller than California, but given that Washington state was experiencing the effects of Covid-19 earlier and more intensely than any other state, due to a major outbreak near Seattle, it is surprising that there are not more tweets about it.',
  }, {
    type: 'image',
    content: image1,
    caption: 'Wednesday February 19, 2020',
  }, {
    type: 'text',
    content: 'Another relative surprise is the limited amount of discussion observed in New York state during the time period under scrutiny. NY is the fourth most populated state in the US (19M), after California (39M), Texas (28M) and Florida (21M). The top three states are evidently the ones where most of the Covid-19 discussion occurred. However, NY doesn’t exhibit the same level of Twitter engagement. As of today (March 18, 2020), according to the CDC, NY has already reported over 2600 cases of Covid-19 infections, leading by a large margin, this unfortunate ranking.',
  }, {
    type: 'text',
    content: 'With respect to the rest of the world, the US has many more discussions on Twitter about the Coronavirus. This is not surprising. What is interesting is Italy. The outbreak officially began in Italy on Feb 20, 2020. The very next day, the number of Twitter posts about the disease observed in Italy began to spike.',
  }, {
    type: 'image',
    content: image2,
    caption: 'February 19, 2020',
  }, {
    type: 'image',
    content: image3,
    caption: 'February 20, 2020',
  }, {
    type: 'image',
    content: image4,
    caption: 'February 21, 2020',
  }, {
    type: 'image',
    content: image5,
    caption: 'February 22, 2020',
  }, {
    type: 'text',
    content: 'We will continue to probe the data to measure how people are responding to this unprecedented event.',
  }],
}, {
  slug: 'covid-19',
  title: 'Twitter dataset related to COVID-19/Coronavirus released!',
  subtitle: '',
  date: 'March 16, 2020',
  authors: 'Emily Chen, Kristina Lerman, Emilio Ferrara.',
  body: [{
    type: 'text',
    content: 'The COVID-19 pandemic is a social emergency, as much as a medical one. The pandemic, which is growing out of control in most of the world’s countries, is straining health resources, economies, trust, and the fabric of society. To mitigate the spread, many countries and municipalities have instituted a patchwork of social distancing measures, including travel bans, quarantines, school closures, event cancellations and wholesale lockdowns. People around the world have turned to social media en masse for information about the pandemic, and also to express their feelings and opinions. To facilitate research into the social dimension of the COVID-19 pandemic, we are releasing to the research community a dataset containing tweets related to Twitter discussions about COVID-19. We hope that the data will enable the study of online conversation dynamics in the context of a global outbreak of unprecedented proportions and implications.',
  }, {
    type: 'text',
    content: 'This dataset could help track scientific information about the coronavirus, as well as misinformation and unverified rumors, and also help understand social response to the pandemic, including fear and panic, and much more. Ultimately, we hope that this dataset will contribute towards enabling informed solutions and prescribing targeted policy interventions to fight---and prevent---this global crisis.',
  }],
  data: {
    title: 'https://github.com/echen102/COVID-19-TweetIDs',
    link: 'https://github.com/echen102/COVID-19-TweetIDs',
  },
  publication: {
    title: '#COVID-19: The First Public Coronavirus Twitter Dataset',
    link: 'https://arxiv.org/abs/2003.07372',
  },
}]
