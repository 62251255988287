import React from 'react'
import {TimelineMax, Power2} from 'gsap'


class Water extends React.Component {

  componentDidMount() {
    this.timeline = new TimelineMax({paused:true})
    this.timeline
      .to('.water-1', 3, {
        fill: '#004266',
        ease: Power2.easeInOut,
      }, 'start')
      .to('.water-2', 3, {
        fill: '#003a59',
        ease: Power2.easeInOut,
      }, 'start')
      .to('.water-3', 3, {
        fill: '#00324d',
        ease: Power2.easeInOut,
      }, 'start')
      .to('.water-4', 3, {
        fill: '#002940',
        ease: Power2.easeInOut,
      }, 'start')
      .to('.water-5', 3, {
        fill: '#002133',
        ease: Power2.easeInOut,
      }, 'start')
      .to('.water-6', 3, {
        fill: '#001926',
        ease: Power2.easeInOut,
      }, 'start')
      .fromTo('body', 3, {
        background: 'linear-gradient(#005380, #002133)',
      }, {
        background: 'linear-gradient(#001926, #000000)',
        ease: Power2.easeInOut,
      }, 'start')
  }

  componentDidUpdate(props) {
    const {animate} = this.props
    if ( animate ) {
      this.timeline.play()
    } else {
      this.timeline.reverse()
    }
  }

  render() {
    return (
      <React.Fragment>
        <path className="water-1" data-depth="0.15" fill="#00A5FF" d="M-100 600h1700v200H-100z"/>
        <path className="water-2" data-depth="0.17" fill="#0095E6" d="M-100 700h1700v200H-100z"/>
        <path className="water-3" data-depth="0.19" fill="#0084CC" d="M-100 800h1700v200H-100z"/>
        <path className="water-4" data-depth="0.21" fill="#0074B3" d="M-100 900h1700v200H-100z"/>
        <path className="water-5" data-depth="0.23" fill="#006399" d="M-100 1000h1700v200H-100z"/>
        <path className="water-6" data-depth="0.25" fill="#005380" d="M-100 1100h1700v200H-100z"/>
      </React.Fragment>
    )
  }
}


export default Water
