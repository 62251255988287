import React, { useEffect } from 'react'
import { TimelineMax, Back } from 'gsap'
import { Fade, Typography } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'


const styles = theme => ({
  arrow: {
    padding: theme.spacing(3),
    position: 'absolute',
    textAlign: 'center',
    color: 'white',
    bottom: '1vh',
    right: 0,
    left: 0,
  },
})


const HideArrow = props => {
  const { children } = props
  const trigger = useScrollTrigger()
  return (
    <Fade in={!trigger} timeout={{enter: 1500, exit: 250}}>
      {children}
    </Fade>
  )
}


const Arrow = (props) => {

  const timeline = new TimelineMax({
    repeat: -1,
    repeatDelay: 5,
  })

  useEffect(() => {
    timeline.to('#arrow-icon', 0.25, {
      scale: 1.25,
      ease: Back.ease,
    }).to('#arrow-icon', 0.25, {
      scale: 1,
      ease: Back.ease,
    })
  })

  useEffect(() => {
    return () => {
      this.timeline.remove()
    }
  })

  const renderArrow = (props) => {
    const {classes} = props
    return (
      <HideArrow {...props}>
        <Typography
          component="p"
          variant="body1"
          className={classes.arrow}>
          scroll down for more <br/>
          <ArrowDownward
            id="arrow-icon"
            fontSize={'large'}
            className={classes.icon} />
        </Typography>
      </HideArrow>
    )
  }

  return (
    <React.Fragment>
      {renderArrow(props)}
    </React.Fragment>
  )
}


export default withStyles(styles)(Arrow)
