import React from 'react'


class Road extends React.Component {

  render() {
    return (
      <React.Fragment>
        <path className="road" data-depth="0.15" fill="#222" d="M-100 590h1700v10H-100z"/>
      </React.Fragment>
    )
  }
}


export default Road
