import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  contact: {
    margin: '5vw',
    width: '90vw',
    padding: theme.spacing(3),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4),
      width: '90vw',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5),
      width: '80vw',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5),
      width: '70vw',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5),
      width: '60vw',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5),
      width: '50vw',
    },
  },
  title: {
    color: '#111',
    margin: theme.spacing(3, 0, 5, 0),
  },
  text: {
    color: '#111',
    margin: theme.spacing(2, 0),
  },
})


class Contact extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <div id="contact" style={{paddingTop: '3vh'}}>
        <Paper elevation={3} className={classes.contact}>
          <Typography
            component="h4"
            variant="h4"
            className={classes.title}>
            Contact
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.text}>
            Reach out if you have any questions about the research projects, scientific publications or open positions.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.text}>
            Email:<br/>
            <a href="mailto:minds-info@isi.edu">minds-info@isi.edu</a>
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.text}>
            Address:<br/>
            4676 Admiralty Way #1001<br/>
            Marina Del Rey, CA 90292
          </Typography>
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d799.8673132016735!2d-118.44061084173649!3d33.98035991664791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ba807e9dc4f7%3A0xcbfb9ebc54219723!2sUSC%20Information%20Sciences%20Institute!5e0!3m2!1sen!2sus!4v1580863419298!5m2!1sen!2sus" width="100%" height="450" frameBorder="0" style={{'border': '0'}} allowFullScreen=""></iframe>
        </Paper>
      </div>
    )
  }
}


export default withStyles(styles)(Contact)
