import React from 'react'
import { Card, CardActionArea, CardMedia, CardContent, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
  faculty,
  researchers,
  students,
  alumni,
} from '../content/people'


const styles = theme => ({
  wrapper: {
    margin: '5vw',
    width:'90vw',
    padding: theme.spacing(3, 0),
    '@media (min-width:600px)': {
      padding: theme.spacing(3, 0),
      width:'90vw',
    },
    '@media (min-width:800px)': {
      padding: theme.spacing(3, 0),
    },
    '@media (min-width:1000px)': {
      padding: theme.spacing(4, 0),
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5, 0),
    },
  },
  title: {
    color: 'white',
    margin: theme.spacing(3, 0, 10, 0),
  },
  person: {
    display: 'inline-block',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  content: {
    textAlign: 'center',
    color: '#111',
  },
  media: {
    display: 'block',
    width: '100%',
    height: 'auto',
    paddingTop: '100%',
    minWidth: '200px',
    minHeight: '200px',
  },
})


class People extends React.Component {

  renderPerson(person) {
    const {classes} = this.props
    return (
      <Card>
        <CardActionArea href={person.link} target="_blank">
          <CardContent className={classes.content}>
            <h2>{person.name}</h2>
          </CardContent>
          <CardMedia
            className={classes.media}
            image={person.image}
            title={person.name} />
        </CardActionArea>
      </Card>
    )
  }

  renderPeople(title, people) {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Typography
          component="h4"
          variant="h4"
          className={classes.title}>
          {title}
        </Typography>
        <Grid container spacing={5}>
        {people.map(person => (
          <Grid key={person.name}
            item xs={12} sm={5} md={4} lg={2}
            className={classes.person}>
            {this.renderPerson(person)}
          </Grid>
        ))}
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <div id="people" className={classes.wrapper}>
        {this.renderPeople('Faculty', faculty)}
        {this.renderPeople('Researchers', researchers)}
        {this.renderPeople('PhD Students', students)}
        {this.renderPeople('Alumni', alumni)}
      </div>
    )
  }
}


export default withStyles(styles)(People)
