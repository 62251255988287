import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  footer: {
    color: 'white',
    textAlign: 'center',
    marginBottom: '3vh',
    position: 'relative',
    right: 0,
    left: 0,
  },
})


class Footer extends React.Component {

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Typography component="p" variant="body1" className={classes.footer}>
          Copyright © 2022 University of Southern California
        </Typography>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Footer)
