import BinAIImage from '../images/binary.jpg'
import GAWSEEDImage from '../images/signals.jpg'
import SAGEImage from '../images/charts.jpg'
import LwLLImage from '../images/alsocode.jpg'
import UBGImage from '../images/networks.jpg'
import DSBoxImage from '../images/dashboard.jpg'
import BigMechanismImage from '../images/bio_medical.jpg'
import NGS2Image from '../images/analytics.jpg'
import EffectImage from '../images/code.jpg'


export const projects = [{
  active: true,
  name: 'BinAI',
  slug: 'binai',
  image: BinAIImage,
  subtitle: 'Machine Learning for Binary Program Analysis',
  description: 'Reverse engineering is widely used to ensure that systems do not have any major security flaws or vulnerabilities. It aids binary program analysis in applications ranging from malware research to protecting against software theft by understanding the complexity of restoring core algorithms. Instead of labor intensive manual analysis, researchers often rely on combination of formal models and heuristics. The goal of this project is automating binary program analysis with machine learning approximations to improve performance while keeping the analysis fast.',
}, {
  active: true,
  name: 'GAWSEED',
  slug: 'gawseed',
  image: GAWSEEDImage,
  subtitle: 'Global Analysis of Week Signals for Enterprise Event Detection',
  description: 'This project is focused on advancing state-of-the-art for detection of network and host-based threats. The goal is to infer and recognize statistical patterns in the temporal evolution of those features that are highly predictive of certain types of malicious activities. These different measurements are not independent and often co-vary according to hidden structures. We are developing a suite of efficient models to extract useful knowledge from massive temporal streams that can handle immense complexity and volume of the data.',
}, {
  active: false,
  name: 'UGB',
  slug: 'ugb',
  image: UBGImage,
  subtitle: 'Understanding Group Biases',
  description: 'Ethnographies are resources generated by social scientists to understand the beliefs, values, and traditions of a culture. The problem is that they are labor intensive and difficult to build. The ultimate goal of Understanding Group Biases (UGB) is to do “big ethnography” using machine learning and NLP to automate the laborious task of anthropologists who spend years doing field research in order to study different cultures. UGB aims to detect cultural models using machine learning and natural language processing (NLP) tools applied to huge swaths of web data.',
}, {
  active: false,
  name: 'SAGE',
  slug: 'sage',
  image: SAGEImage,
  subtitle: 'Synergistic Anticipation of Geopolitical Events',
  description: 'SAGE is a team of social and computer scientists working together to create better tools to predict the future. The SAGE team is working to build a hybrid forecasting platform that utilizes the unique strengths of both humans and machine models. SAGE predictions are primarily focused on global politics, economics, science, business, and entertainment. SAGE uses unique data tools and machine learning methods to “hybridize” the forecasting process. We’re not a prediction or betting market: sage free to enter, so you’re never betting your own money.',
}, {
  active: true,
  name: 'LwLL',
  slug: 'lwll',
  image: LwLLImage,
  subtitle: 'Learning with Less Labels',
  description: 'Training accurate machine learning (ML) models such as deep neural networks currently requires lots of labeled data, which is often costly to annotate. The overarching goal is to develop revolutionary ML methods that will achieve state-of-the-art performance on a broad range of tasks while requiring significantly fewer labeled instances of the data. Our technical approach is rooted in the intuition that drastic reduction of label-dependency for a given ML task must be compensated by introducing a strong inductive bias for that task.',
}, {
  active: false,
  name: 'DSBox',
  slug: 'dsbox',
  image: DSBoxImage,
  subtitle: 'Data Scientist in a Box  (for the DARPA D3M program, "Data Driven Discovery of Models")',
  description: 'In today’s big data era, there are tremendous opportunities for exploiting available information to solve many different kinds of problems by building empirical, data-driven models of complex phenomena. Yet, building such models is generally an elaborate process, often requiring a data scientist trained in an evolving array of technologies. Subject matter experts have the domain knowledge required to deﬁne useful empirical models, but often lack the computer science expertise to construct them from scratch.',
}, {
  active: false,
  name: 'Big Mechanism',
  slug: 'big-mechanism',
  image: BigMechanismImage,
  subtitle: 'Automated Discovery of Cancer Pathway Models from Biomedical Text',
  description: 'The goal of this project is to revolutionize cancer biology (and discovery science in general) by developing capabilities that will allow to (i) automatically read biomedical literature on cancer pathways (ii) extract causal fragments from the literature (iii) assemble those fragments into a full causal model of a cancer pathway. BM is a highly interdisciplinary project that involves ideas and people from various disciplines – cancer & system biology, machine learning, natural language processing (NLP), among others.',
}, {
  active: false,
  name: 'EFFECT',
  slug: 'effect',
  image: EffectImage,
  subtitle: 'Anticipating Large-Scale Cybersecurity Breaches',
  description: 'Addressing rapidly growing cyber threats posed by a variety of state and non-state actors is of paramount importance. EFFECT (Effectively Forecasting Evolving Cyber Threats)seeks to develop technologies that counter these threats by anticipating them before they manifest in the form of an actual cyberattack. In particular, our objective is to develop an end-to-end prototype that accurately forecasts emerging cyber threats (including source, target, attack time, and vector of vulnerabilities) by integrating information from a variety of novel, unconventional sensors within a robust inference framework.',
}, {
  active: false,
  name: 'NGS2',
  slug: 'ngs2',
  image: NGS2Image,
  subtitle: 'Next Generation Social Science',
  description: 'Humans are complex and many diverse and overlapping factors may affect any human decision. Social science research has generally been limited to small studies that attempt to isolate and test the effects of individual factors proposed by investigators. In principle, the emergence of large-scale data about human behavior based on ubiquitous sensors and computing could allow us to automatically disentangle diverse factors behind human behavior in a principled, data-driven way. The goal of this project is to automatically discover the most important factors that affect human behavior.',
}]
