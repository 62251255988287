import React from 'react'
import {TimelineMax} from 'gsap'
import Parallax from 'parallax-js'

import Sky from './animation/Sky'
import Road from './animation/Road'
import Water from './animation/Water'
import Mountains from './animation/Mountains'
import NorthTower from './animation/NorthTower'
import SouthTower from './animation/SouthTower'
import Traffic from './animation/Traffic'


class Animation extends React.Component {

  constructor(props) {
    super(props)
    const timeline = this.createTimeline()
    this.state = {animate: false, timeline}
    document.addEventListener('mousemove', () => this.handleOnMove())
    document.addEventListener('touchmove', () => this.handleOnMove())
    document.addEventListener('scroll', () => this.handleOnMove())
  }

  resetIdleTimeout() {
    this.setState({animate: false}, () => {
      clearTimeout(this.idleTimeoutId)
      this.idleTimeoutId = setTimeout(() => {
        this.setState({animate: true})
      }, 60*1000)
    })
  }

  handleOnMove() {
    clearTimeout(this.mouseMoveTimeout)
    this.mouseMoveTimeout = setTimeout(() => {
      this.resetIdleTimeout()
    }, 250)
  }

  createTimeline() {
    return new TimelineMax({
      onComplete: () => {
        const timeline = this.createTimeline()
        this.setState({timeline})
      },
    })
  }

  componentDidMount() {
    this.parallax = new Parallax(this.scene)
    this.resetIdleTimeout()
  }

  componentWillUnmount() {
    this.parallax.disable()
    document.addEventListener('mousemove', () => this.handleOnMove())
    document.addEventListener('touchmove', () => this.handleOnMove())
    document.addEventListener('scroll', () => this.handleOnMove())
  }

  render() {
    const { animate, timeline } = this.state
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1200">
        <g fill="none" fillRule="evenodd" ref={el => this.scene = el}>
          <Sky animate={animate} />
          <Mountains animate={animate} />
          <Water animate={animate} />
          <Road />
          <NorthTower animate={animate} />
          <SouthTower animate={animate} />
          <Traffic timeline={timeline} />
        </g>
      </svg>
    )
  }
}


export default Animation
