import React from 'react'

class Vehicle extends React.Component {

  render() {
    return (
      <g data-depth="0.15">
        {this.props.children}
      </g>
    )
  }

}

export default Vehicle
