import React from 'react'
import {TimelineMax, Back, Power2} from 'gsap'


class Sky extends React.Component {

  createTimeline() {
    this.timeline = new TimelineMax({paused:true})
    this.timeline
      .fromTo('.sky', 3, {
        fill: '#0CB3E9',
        ease: Power2.easeInOut,
      }, {
        fill: '#09121f',
        ease: Power2.easeInOut,
      }, 'start')
    document.querySelectorAll('.stars .star').forEach((star) => {
      this.timeline
        .fromTo(star, 2, {
          opacity: 0,
        }, {
          opacity: 0.5 + Math.random() * 0.5,
          delay: Math.random() * 2,
          ease: Back.easeOut,
        }, 'start')
    })
  }

  componentDidUpdate(props) {
    const {animate} = this.props
    if ( animate ) {
      this.createTimeline()
      this.timeline.play()
    } else {
      if ( !!this.timeline ) {
        this.timeline.reverse()
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <path className="sky" data-depth="0.025" fill="#0CB3E9" d="M-100-100h1700v1400H-100"/>
        <g className="stars">
          <circle className="star" opacity="0" fill="#FFF" cx="181" cy="38" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="341" cy="68" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="541" cy="48" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="101" cy="108" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="721" cy="98" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="771" cy="18" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="881" cy="126" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1131" cy="156" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1281" cy="66" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1401" cy="136" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1370.5" cy="84.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1250.5" cy="104.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1051.5" cy="103.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1030.5" cy="134.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1000.5" cy="124.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1030.5" cy="80.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1051.5" cy="50.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1084.5" cy="22.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="1125.5" cy="12.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="800.5" cy="44.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="600.5" cy="84.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="410.5" cy="24.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="280.5" cy="124.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="60.5" cy="54.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="30.5" cy="134.5" r="1"/>
          <circle className="star" opacity="0" fill="#FFF" cx="220.5" cy="134.5" r="1"/>
        </g>
      </React.Fragment>
    )
  }
}


export default Sky
