import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

import logo from '../images/isi_logo.png'


const styles = theme => ({
  intro: {
    position: 'absolute',
    top: '15vh',
    left: '5vw',
    width: '90vw',
    padding: theme.spacing(3),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4),
      width: '90vw',
      top: '30vh',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5),
      width: '80vw',
      top: '35vh',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5),
      width: '70vw',
      top: '35vh',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5),
      width: '60vw',
      top: '35vh',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5),
      width: '50vw',
      top: '35vh',
    },
  },
  logo: {
    width: '50%',
  },
  title: {
    color: '#111',
    margin: theme.spacing(3, 0),
  },
  text: {
    color: '#111',
    margin: theme.spacing(3, 0),
  },
})


class Intro extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Paper elevation={3} className={classes.intro}>
          <img src={logo} className={classes.logo} alt="isi-logo" />
          <Typography
            component="h3"
            variant="h3"
            className={classes.title}>
            Machine Intelligence and Data Science
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.text}>
            The Machine INtelligence and Data Science (MINDS) research group is a part of the University of Southern California’s Information Sciences Institute, located in sunny Marina del Rey, CA. We deploy and develop machine learning algorithms to analyze data from a variety of applications, including social science, cybersecurity, and biomedical domains.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.text}>
            We are currently hiring for several postdocs and internship positions!
          </Typography>
        </Paper>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Intro)
