import React from 'react'
import {TimelineMax, Back} from 'gsap'


class NorthTower extends React.Component {

  createTimeline() {
    this.timeline = new TimelineMax({paused:true})
    for ( let i = 1; i <= 18; i++ ) {
      for ( let j = 1; j <= 2; j++ ) {
        let row = Math.floor(Math.random() * 18) + 1
        let win = Math.floor(Math.random() * 11) + 2
        let delay = Math.random() * 3
        this.timeline
          .fromTo(`.north-tower .upper-main-windows-${row} .window-${win}`, 1, {
            fill: '#333',
          }, {
            fill: '#86816B',
            ease: Back.easeOut,
          }, `start+=${delay}`)
      }
    }

    for ( let i = 1; i <= 9; i++ ) {
      for ( let j = 1; j <= 2; j++ ) {
        let row = Math.floor(Math.random() * 9) + 1
        let win = Math.floor(Math.random() * 11) + 2
        let delay = Math.random() * 3
        this.timeline
          .fromTo(`.north-tower .upper-side-windows-${row} .window-${win}`, 1, {
            fill: '#333',
          }, {
            fill: '#787360',
            ease: Back.easeOut,
          }, `start+=${delay}`)
      }
    }
  }

  componentDidUpdate(props) {
    const {animate} = this.props
    if ( animate ) {
      this.createTimeline()
      this.timeline.play()
    } else {
      if ( !!this.timeline ) {
        this.timeline.reverse()
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <g className="north-tower" data-depth="0.15">
          <g className="north-tower-structure" transform="translate(50 218)">
            <g className="north-tower-roof" transform="translate(74.004)" fill="#000">
              <path className="north-tower-roof-side" d="M200 0l63 7v38l-63-7z"/>
              <path className="north-tower-roof-main" d="M0 0h200v38H0z"/>
            </g>
            <path className="north-tower-building-side" fill="#EFEFEF" d="M309.004 42l94 10v325h-94z"/>
            <path className="north-tower-building-main" fill="#EFEFEF" d="M1.004 42h308v335h-308z"/>
            <path className="north-tower-roof-edge" fill="#EFEFEF" d="M0 38h309.004l95 11v4l-95-10H0z"/>
            <path className="north-tower-roof-edge-shadow" fill="#C5C5C5" d="M0 42h309.004l95 10v1l-95-10H0z"/>
          </g>
          <g className="north-tower-main-windows" transform="translate(53 263)">
            <g className="north-tower-lower-main-windows" transform="translate(0 305)">
              <g className="lower-main-windows-1">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
              <g className="lower-main-windows-2" transform="translate(51)">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
              <g className="lower-main-windows-3" transform="translate(102)">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
              <g className="lower-main-windows-4" transform="translate(153)">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
              <g className="lower-main-windows-5" transform="translate(204)">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
              <g className="lower-main-windows-6" transform="translate(255)">
                <path className="front-window-glass" fill="#000" d="M0 0h49v25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M32 0h2v25h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M15 0h2v25h-2z"/>
              </g>
            </g>
            <g className="north-tower-upper-main-windows">
              <g className="upper-main-windows-1">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-2" transform="translate(17)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-3" transform="translate(34)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-4" transform="translate(51)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-5" transform="translate(68)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-6" transform="translate(85)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-7" transform="translate(102)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-8" transform="translate(119)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-9" transform="translate(136)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-10" transform="translate(153)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-11" transform="translate(170)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-12" transform="translate(187)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-13" transform="translate(204)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-14" transform="translate(221)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-15" transform="translate(238)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-16" transform="translate(255)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-17" transform="translate(272)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-18" transform="translate(289)">
                <path d="M0 1.503C1.665.5 4.165 0 7.5 0s5.835.5 7.5 1.503V298.5c-1.68 1-4.18 1.5-7.5 1.5-3.32 0-5.82-.5-7.5-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4h15v12H0z"/>
                <path className="window-11" fill="#333" d="M0 32h15v12H0z"/>
                <path className="window-10" fill="#333" d="M0 60h15v12H0z"/>
                <path className="window-9" fill="#333" d="M0 88h15v12H0z"/>
                <path className="window-8" fill="#333" d="M0 116h15v12H0z"/>
                <path className="window-7" fill="#333" d="M0 144h15v12H0z"/>
                <path className="window-6" fill="#333" d="M0 172h15v12H0z"/>
                <path className="window-5" fill="#333" d="M0 200h15v12H0z"/>
                <path className="window-4" fill="#333" d="M0 228h15v12H0z"/>
                <path className="window-3" fill="#333" d="M0 256h15v12H0z"/>
                <path className="window-2" fill="#333" d="M0 284h15v12H0z"/>
                <path d="M7.5.503v298.984" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
            </g>
          </g>
          <g className="north-tower-side-windows" transform="translate(361 263)">
            <g className="north-tower-lower-side-windows" transform="translate(0 305)">
              <g className="lower-side-windows-1">
                <path className="front-window-glass" fill="#000" d="M0 0l20 2.4V25H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M13 1.558l1 .122V25h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M6 .724l1 .12V25H6z"/>
              </g>
              <g className="lower-side-windows-2" transform="translate(21 2)">
                <path className="front-window-glass" fill="#000" d="M0 .4l20 2.4V23H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M13 1.945l1 .122V23h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M6 1.114l1 .12V23H6z"/>
              </g>
              <g className="lower-side-windows-3" transform="translate(42 4)">
                <path className="front-window-glass" fill="#000" d="M0 .8l20 2.4V21H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M13 2.36l1 .121V21h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M6 1.524l1 .12V21H6z"/>
              </g>
              <g className="lower-side-windows-4" transform="translate(63 7)">
                <path className="front-window-glass" fill="#000" d="M0 .2l13 1.6V18H0z"/>
                <path className="front-window-divider-1" fill="#333" d="M6 .947l1 .12V18H6z"/>
              </g>
              <g className="lower-side-windows-5" transform="translate(77 8)">
                <path className="front-window-glass" fill="#000" d="M0 .8l13 1.6V17H0z"/>
                <path className="front-window-divider-1" fill="#333" d="M6 1.538l1 .12V17H6z"/>
              </g>
            </g>
            <g className="north-tower-upper-side-windows">
              <g className="upper-side-windows-1">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-2" transform="translate(7 .8)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-3" transform="translate(14 1.6)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-4" transform="translate(21 2.4)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-5" transform="translate(28 3.2)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-6" transform="translate(35 4)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-7" transform="translate(42 4.8)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-8" transform="translate(49 5.6)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-9" transform="translate(56 6.4)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-10" transform="translate(63 7.2)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-11" transform="translate(70 8)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-12" transform="translate(77 8.8)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-13" transform="translate(84 9.6)">
                <path d="M0 1.503C.666.5 1.666 0 3 0s2.334.5 3 1.503V298.5c-.672 1-1.672 1.5-3 1.5s-2.328-.5-3-1.5V1.503z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 4l6 .8v12L0 16z"/>
                <path className="window-11" fill="#333" d="M0 32l6 .8v12L0 44z"/>
                <path className="window-10" fill="#333" d="M0 60l6 .8v12L0 72z"/>
                <path className="window-9" fill="#333" d="M0 88l6 .8v12l-6-.8z"/>
                <path className="window-8" fill="#333" d="M0 116l6 .8v12l-6-.8z"/>
                <path className="window-7" fill="#333" d="M0 144l6 .8v12l-6-.8z"/>
                <path className="window-6" fill="#333" d="M0 172l6 .8v12l-6-.8z"/>
                <path className="window-5" fill="#333" d="M0 200l6 .8v12l-6-.8z"/>
                <path className="window-4" fill="#333" d="M0 228l6 .8v12l-6-.8z"/>
                <path className="window-3" fill="#333" d="M0 256l6 .8v12l-6-.8z"/>
                <path className="window-2" fill="#333" d="M0 284l6 .8v12l-6-.8z"/>
                <path d="M3 .5v299.002" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
            </g>
          </g>
        </g>
      </React.Fragment>
    )
  }
}


export default NorthTower
