import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from './Menu'
import Intro from './Intro'
import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'
import News from './News'
import Projects from './Projects'
import Publications from './Publications'
import People from './People'
import Contact from './Contact'


const styles = theme => ({
  content: {
    marginTop: '100vh',
  },
})


class Content extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <div className={classes.content}>
        <Menu />
        <Intro />
        <ArrowDown />
        <News />
        <Projects />
        <Publications />
        <People />
        <Contact />
        <ArrowUp />
      </div>
    )
  }
}


export default withStyles(styles)(Content)
