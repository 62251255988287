export const faculty = [{
  name: 'Aram  Galstyan',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-26.png',
  link: 'https://www.isi.edu/people/galstyan/about',
}, {
  name: 'Kristina Lerman',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/lerman-1.jpg',
  link: 'https://www.isi.edu/directory/lerman/',
}, {
  name: 'Emilio Ferrara',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-319.jpg',
  link: 'https://www.isi.edu/people/ferrarae/about',
}, {
  name: 'Greg Ver Steeg',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-27.jpg',
  link: 'https://www.isi.edu/people/gregv/about',
}, {
  name: 'Fred Morstatter',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-354.jpg',
  link: 'https://www.isi.edu/directory/fredmors/',
}]


export const researchers = [{
  name: 'Goran Murić',
  image: 'http://gmuric.com/images/me.jpg',
  link: 'http://gmuric.com/',
}, {
  name: 'Marcin Abram',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-452.jpg',
  link: 'https://www.isi.edu/people/mjabram/about',
}]


export const students = [{
  name: 'Rob Brekelmans',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-360.jpg',
  link: 'https://www.isi.edu/directory/brekelma/',
}, {
  name: 'Shushan Arakelyan',
  image: 'https://shushanarakelyan.github.io/img/portrait.jpg',
  link: 'http://shushanarakelyan.github.io/',
}, {
  name: 'Sarik Ghazarian',
  image: 'http://scf.usc.edu/~sarikgha/images/author.jpg',
  link: 'http://scf.usc.edu/~sarikgha/',
}, {
  name: 'Hrayr Harutyunyan',
  image: 'https://hrayrhar.github.io/images/profile.png',
  link: 'https://hrayrhar.github.io/',
}, {
  name: 'Hsien-Te Kao',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-358.jpg',
  link: 'https://www.isi.edu/people/hsiente/about',
}, {
  name: 'Neal Lawton',
  image: 'https://media-exp1.licdn.com/dms/image/C5603AQGxhmY444lsnw/profile-displayphoto-shrink_200_200/0/1516497050194?e=1643846400&v=beta&t=VoWQF1my7EtDk0s1fzSnpg7DmYQZlhOxvEqGVE-Ag5w',
  link: 'https://www.isi.edu/directory/lawton/',
}, {
  name: 'Ninareh Mehrabi',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/ninarehm-4.jpg',
  linke: 'https://www.isi.edu/directory/ninarehm/',
}, {
  name: 'Yuzhong Huang',
  image: 'http://www.yuzhonghuang.org/images/bio-photo.jpg',
  link: 'http://www.yuzhonghuang.org/',
}, {
  name: 'Akira Matsui',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/akira-1.jpg',
  link: 'https://www.isi.edu/directory/akira/',
}, {
  name: 'Sami Abu-El-Haija',
  image: 'https://samihaija.github.io/images/linkedin.jpg',
  link: 'https://samihaija.github.io/',
}, {
  name: 'Kyle Reing',
  image: 'https://media-exp1.licdn.com/dms/image/C4E03AQEMyAfS6VSHPw/profile-displayphoto-shrink_200_200/0/1516501151370?e=1643846400&v=beta&t=JpRqJu0rSuL4u2sqBybZKTVptjdVodBgkNPhSYy_LiQ',
  link: 'https://www.isi.edu/directory/reing/',
}, {
  name: 'Mehrnoosh Mirtaheri',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/mehrnoom-3.jpg',
  link: 'https://www.isi.edu/directory/mehrnoom/',
}, {
  name: 'Serban Stan',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/stan-1.jpg',
  link: 'https://www.isi.edu/directory/stan/',
}, {
  name: 'Myrl Marmarelis',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/myrlm-1.jpg',
  link: 'https://myrl.marmarel.is/ ',
}, {
  name: 'Yilei Zeng',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/yileiz-1.jpg',
  link: 'https://www.isi.edu/directory/yileiz/',
}, {
  name: 'Bahare Harandi',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/harandiz-1.jpg',
  link: 'https://www.isi.edu/directory/harandiz/',
}]


export const alumni = [{
  name: 'Daniel Moyer',
  image: 'https://pbs.twimg.com/profile_images/1055185299003793408/nVEXgyAu_400x400.jpg',
  link: 'http://www-scf.usc.edu/~moyerd/',
}, {
  name: 'Palash Goyal',
  image: 'https://avatars3.githubusercontent.com/u/1177157?s=460&u=36f9b94aea73b87bf8de4f6e16fbeeb498c60fc7',
  link: 'https://github.com/palash1992',
}, {
  name: 'Shuyang Gao',
  image: 'http://billgao.weebly.com/uploads/5/2/7/3/52730421/published/img-0368_2.jpg?1542703573',
  link: 'http://billgao.weebly.com/',
}, {
  name: 'Sahil Garg',
  image: 'http://www-scf.usc.edu/~sahilgar/sahil.jpg',
  link: 'http://www-scf.usc.edu/~sahilgar/',
}, {
  name: 'Dave Kale',
  image: 'http://www-scf.usc.edu/~dkale/images/tricorder.jpg',
  link: 'http://www-scf.usc.edu/~dkale/',
}, {
  name: 'Andrés Abeliuk',
  image: 'https://aabeliuk.github.io/images/profile_andres.jpg',
  link: 'https://aabeliuk.github.io/',
}, {
  name: 'Nanyun Peng',
  image: 'https://www.isi.edu/sites/default/files/photos/users/picture-361.jpg',
  link: 'http://vnpeng.net/',
}, {
  name: 'Nazgol Tavabi',
  image: 'https://media-exp1.licdn.com/dms/image/C5603AQEvYkYoeKnTvw/profile-displayphoto-shrink_200_200/0/1557993184097?e=1643846400&v=beta&t=KqoGXNCEp2Kug-CUP9kQJUDGp9IvqMBjIU0Dzfqd-hs',
  link: 'https://www.linkedin.com/in/nazgol-tavabi-658128186/',
}, {
  name: 'Nazanin AlipourFard',
  image: 'https://media-exp1.licdn.com/dms/image/C5603AQHUfJp_c7Brvg/profile-displayphoto-shrink_200_200/0/1627427097458?e=1643846400&v=beta&t=nOWsI5CwtDaKmdkUpu03XavIJVDbLLnK4eQFnIIquBQ',
  link: 'https://www.linkedin.com/in/nazanin-alipourfard-82463542/',
}, {
  name: 'Daniel Benjamin',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/benjamin-2.jpg',
  link: 'https://www.isi.edu/directory/benjamin/',
}, {
  name: 'Ashok Deb',
  image: 'https://media-exp1.licdn.com/dms/image/C4D03AQGvvK1XY4z7JA/profile-displayphoto-shrink_200_200/0/1516823038453?e=1643846400&v=beta&t=XwyANgN6YDFcuDcsT177JH8jEdwkfz6zCywx1MtJJ7k',
  link: 'https://www.linkedin.com/in/ashok-deb-312a7019/',
}]
