import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Animation from './Animation'


const styles = theme => ({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
})


class Header extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <div className={classes.header}>
        <div id="top" />
        <Animation />
      </div>
    )
  }
}


export default withStyles(styles)(Header)
