import React from 'react'

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'


// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)


const styles = theme => ({
  '@global': {
    body: {
      color: '#111',
      background: 'linear-gradient(#005380, #002133)',
    },
  },
})


class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Header />
        <Content />
        <Footer />
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(App)
