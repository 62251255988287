import React from 'react'
import {TimelineMax, Back} from 'gsap'


class SouthTower extends React.Component {

  createTimeline() {
    this.timeline = new TimelineMax({paused:true})
    for ( let i = 1; i <= 18; i++ ) {
      for ( let j = 1; j <= 2; j++ ) {
        let row = Math.floor(Math.random() * 18) + 1
        let win = Math.floor(Math.random() * 11) + 2
        let delay = Math.random() * 3
        this.timeline
          .fromTo(`.south-tower .upper-main-windows-${row} .window-${win}`, 0.25, {
            fill: '#333',
          }, {
            fill: '#86816B',
            ease: Back.easeOut,
          }, `start+=${delay}`)
      }
    }

    for ( let i = 1; i <= 9; i++ ) {
      for ( let j = 1; j <= 2; j++ ) {
        let row = Math.floor(Math.random() * 9) + 1
        let win = Math.floor(Math.random() * 11) + 2
        let delay = Math.random() * 3
        this.timeline
          .fromTo(`.south-tower .upper-side-windows-${row} .window-${win}`, 0.25, {
            fill: '#333',
          }, {
            fill: '#787360',
            ease: Back.easeOut,
          }, `start+=${delay}`)
      }
    }
  }

  componentDidUpdate(props) {
    const {animate} = this.props
    if ( animate ) {
      this.createTimeline()
      this.timeline.play()
    } else {
      if ( !!this.timeline ) {
        this.timeline.reverse()
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <g className="south-tower" data-depth="0.15">
          <g className="south-tower-structure" transform="translate(1030 177)">
            <g className="south-tower-roof" transform="translate(65)" fill="#000">
              <path className="south-tower-roof-side" d="M220 0l56 10v40l-56-10z"/>
              <path className="south-tower-roof-main" d="M0 0h220v40H0z"/>
            </g>
            <path className="south-tower-building-main" fill="#EFEFEF" d="M1 45h326v373H1z"/>
            <path className="south-tower-building-side" fill="#EFEFEF" d="M327 45l84 20v353h-84z"/>
            <path className="south-tower-roof-edge" fill="#EFEFEF" d="M0 40h327.004L412 61v4l-84.996-20H0z"/>
            <path className="south-tower-roof-edge" fill="#C5C5C5" d="M0 44h327.004L412 65.001v1L327.004 45H0z"/>
          </g>
          <g className="south-tower-main-windows" transform="translate(1033 227)">
            <g className="south-tower-lower-main-windows" transform="translate(0 336)">
              <g className="lower-main-window-1">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
              <g className="lower-main-window-2" transform="translate(54)">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
              <g className="lower-main-window-3" transform="translate(108)">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
              <g className="lower-main-window-4" transform="translate(162)">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
              <g className="lower-main-window-5" transform="translate(216)">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
              <g className="lower-main-window-6" transform="translate(270)">
                <path className="front-window-glass" fill="#000" d="M0 0h52v30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M34 0h2v30h-2z"/>
                <path className="front-window-divider-1" fill="#333" d="M16 0h2v30h-2z"/>
              </g>
            </g>
            <g className="south-tower-upper-main-windows">
              <g className="upper-main-windows-1">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-2" transform="translate(18)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-3" transform="translate(36)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-4" transform="translate(54)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-5" transform="translate(72)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-6" transform="translate(90)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-7" transform="translate(108)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-8" transform="translate(126)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-9" transform="translate(144)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-10" transform="translate(162)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-11" transform="translate(180)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-12" transform="translate(198)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-13" transform="translate(216)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-14" transform="translate(234)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-15" transform="translate(252)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-16" transform="translate(270)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-17" transform="translate(288)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-main-windows-18" transform="translate(306)">
                <path d="M0 1.658C1.776.553 4.443 0 8 0c3.557 0 6.224.553 8 1.658v327.687C14.209 330.448 11.542 331 8 331s-6.209-.552-8-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5h16v13H0z"/>
                <path className="window-11" fill="#333" d="M0 35h16v13H0z"/>
                <path className="window-10" fill="#333" d="M0 66h16v13H0z"/>
                <path className="window-9" fill="#333" d="M0 97h16v13H0z"/>
                <path className="window-8" fill="#333" d="M0 128h16v13H0z"/>
                <path className="window-7" fill="#333" d="M0 160h16v13H0z"/>
                <path className="window-6" fill="#333" d="M0 190h16v13H0z"/>
                <path className="window-5" fill="#333" d="M0 221h16v13H0z"/>
                <path className="window-4" fill="#333" d="M0 252h16v13H0z"/>
                <path className="window-3" fill="#333" d="M0 283h16v13H0z"/>
                <path className="window-2" fill="#333" d="M0 314h16v13H0z"/>
                <path d="M8 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
            </g>
          </g>
          <g className="south-tower-side-windows" transform="translate(1359 227)">
            <g className="south-tower-lower-side-windows" transform="translate(0 336)">
              <g className="lower-side-windows-1">
                <path className="front-window-glass" fill="#000" d="M0 0l26 6.75V30H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M17 4.416l1 .26V30h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M8 2.078l1 .26V30H8z"/>
              </g>
              <g className="lower-side-windows-2" transform="translate(27 6)">
                <path className="front-window-glass" fill="#000" d="M0 .75L26 7.5V24H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M17 5.16l1 .26V24h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M8 2.815l1 .26V24H8z"/>
              </g>
              <g className="lower-side-windows-3" transform="translate(54 13)">
                <path className="front-window-glass" fill="#000" d="M0 .5l26 6.75V17H0z"/>
                <path className="front-window-divider-2" fill="#333" d="M17 4.913l1 .26V17h-1z"/>
                <path className="front-window-divider-1" fill="#333" d="M8 2.571l1 .26V17H8z"/>
              </g>
            </g>
            <g className="south-tower-upper-side-windows">
              <g className="upper-side-windows-1">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-2" transform="translate(9 2.25)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-3" transform="translate(18 4.5)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-4" transform="translate(27 6.75)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-5" transform="translate(36 9)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-6" transform="translate(45 11.25)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-7" transform="translate(54 13.5)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-8" transform="translate(63 15.75)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
              <g className="upper-side-windows-9" transform="translate(72 18)">
                <path d="M0 1.658C.888.553 2.221 0 4 0s3.112.553 4 1.658v327.687C7.104 330.448 5.77 331 4 331s-3.104-.552-4-1.655V1.658z" className="window-panel" fill="#000"/>
                <path className="window-12" fill="#333" d="M0 5l8 2.25v13L0 18z"/>
                <path className="window-11" fill="#333" d="M0 35l8 2.25v13L0 48z"/>
                <path className="window-10" fill="#333" d="M0 66l8 2.25v13L0 79z"/>
                <path className="window-9" fill="#333" d="M0 97l8 2.25v13L0 110z"/>
                <path className="window-8" fill="#333" d="M0 128l8 2.25v13L0 141z"/>
                <path className="window-7" fill="#333" d="M0 160l8 2.25v13L0 173z"/>
                <path className="window-6" fill="#333" d="M0 190l8 2.25v13L0 203z"/>
                <path className="window-5" fill="#333" d="M0 221l8 2.25v13L0 234z"/>
                <path className="window-4" fill="#333" d="M0 252l8 2.25v13L0 265z"/>
                <path className="window-3" fill="#333" d="M0 283l8 2.25v13L0 296z"/>
                <path className="window-2" fill="#333" d="M0 314l8 2.25v13L0 327z"/>
                <path d="M4 .51V330.5" className="window-divider" stroke="#222" strokeLinecap="square"/>
              </g>
            </g>
          </g>
        </g>
      </React.Fragment>
    )
  }
}


export default SouthTower
